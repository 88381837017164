@use '../../../app/queries' as *;
@use '../../../shared/styles/fixed' as *;
@use '../../../shared/ui/typography/sizes' as *;

.advertising {
  display: flex;
  position: relative;
  width: 100%;
  height: 56px;
  justify-content: center;
  align-items: center;
  background: var(--cst-advertising-background);
  padding: 9px;
  overflow: hidden;
  gap: 10px;

  .adv-illustration {
    position: absolute;
    z-index: 0;
    pointer-events: none;
    @include fixedSize(398px, 56px);
    &--left {
      left: -100px;
      transform: scale(-1, 1);
    }

    &--right {
      right: -100px;
    }
  }

  .left-slot {
    display: flex;
    align-items: center;
    width: max-content;
  }

  .middle-slot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1;
  }

  .right-slot {
    display: flex;
    align-items: center;
    width: max-content;
  }
}
